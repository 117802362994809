import React, { useEffect, useState } from "react";
import EcnButton from "../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import EcnInput from "../StyledComponents/EcnInput";

import { IconButton, InputAdornment } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import LinearProgress, { linearProgressClasses } from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import {
  hasEightCharacters,
  hasNumber,
  hasSpecialCharacter,
  hasUpperAndLowerCase,
} from "../../helpers/Validations/BasicValidations";
import EcnPopover from "../StyledComponents/EcnPopover";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { postUpdatePassword } from "../../redux/slices/securitySlices/postUpdatePasswordSlice";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  marginTop: 10,
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#E9E7E7",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
  },
  "&.MuiLinearProgress-determinate": {
    backgroundColor: "#E9E7E7",
  },
}));

interface ViewProps {
  drawerCloseHandler: () => void;
}

const PasswordView = ({ drawerCloseHandler }: ViewProps) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { error, loading } = useAppSelector((state) => state.postUpdatePassword);

  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [showOldPass, setShowOldPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState({ num: 0, text: "" });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (password) {
      let strengthCount = 0;

      if (hasEightCharacters(password)) strengthCount++;
      if (hasNumber(password)) strengthCount++;
      if (hasSpecialCharacter(password)) strengthCount++;
      if (hasUpperAndLowerCase(password)) strengthCount++;

      switch (strengthCount) {
        case 1:
          setPasswordStrength({ num: 1, text: "Bad" });
          break;
        case 2:
          setPasswordStrength({ num: 2, text: "Weak" });
          break;
        case 3:
          setPasswordStrength({ num: 3, text: "Intermediate" });
          break;
        case 4:
          setPasswordStrength({ num: 4, text: "Good" });
          break;
        default:
          setPasswordStrength({ num: 0, text: "" });
          break;
      }
    }
  }, [password]);

  const handlePopoverOpen = (event: React.ChangeEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    handlePopoverOpen(e);
  };

  const confirmPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (passwordStrength.num === 4 && e.target.value.length > 0)
      handlePopoverClose();
  };

  const changePasswordHandler = () => {
    dispatch(
      postUpdatePassword({
        oldPassword,
        newPassword: password,
        email: userInfo?.email,
      })
    );
  };

  return (
    <div className="password-view">
      <div className="container">
        <EcnButton
          startIcon={
            <Icon icon="material-symbols:arrow-back" height={24} width={24} />
          }
          color="secondary"
          className="back-btn"
          onClick={drawerCloseHandler}
        >
          Change Password
        </EcnButton>

        <div className="all-fields">
          <div className="field">
            <label htmlFor="">Enter Current Password*</label>
            <EcnInput
              type={showOldPass ? "text" : "password"}
              placeholder="Enter Old Password"
              className="ecn-input signin-input"
              value={oldPassword}
              error={error}
              onChange={(e) => setOldPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowOldPass(!showOldPass)}>
                      <Icon
                        icon={showOldPass ? "lucide:eye" : "lucide:eye-off"}
                        height={24}
                        width={24}
                        color="#212121"
                        strokeOpacity={0.6}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {error && <p className="error-password">Password is incorrect</p>}
          </div>
          <div className="field">
            <label htmlFor="">Enter New Password*</label>

            <EcnInput
              aria-owns={open ? "password-popover" : undefined}
              aria-haspopup="true"
              type={showPass ? "text" : "password"}
              placeholder="Enter New Password"
              className="ecn-input signin-input"
              value={password}
              onChange={passwordHandler}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPass(!showPass)}>
                      <Icon
                        icon={showPass ? "lucide:eye" : "lucide:eye-off"}
                        height={24}
                        width={24}
                        color="#212121"
                        strokeOpacity={0.6}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {password !== "" ? (
              <div style={{ textAlign: "right" }}>
                <BorderLinearProgress
                  variant="determinate"
                  color={
                    [1, 2].includes(passwordStrength.num)
                      ? "error"
                      : passwordStrength.num === 3
                      ? "warning"
                      : "success"
                  }
                  value={(passwordStrength.num / 4) * 100}
                />
                <p
                  style={{
                    color: [1, 2].includes(passwordStrength.num)
                      ? "#ba0000"
                      : passwordStrength.num === 3
                      ? "#fe9800"
                      : "#027A48",
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                >
                  {passwordStrength.text}
                </p>
              </div>
            ) : null}
          </div>
          <div className="field">
            <label htmlFor="">Re-Enter New Password*</label>

            <EcnInput
              type={showConfirmPass ? "text" : "password"}
              placeholder="Re-Enter New Password"
              className="ecn-input signin-input"
              value={confirmPassword}
              onChange={confirmPasswordHandler}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowConfirmPass(!showConfirmPass)}>
                      <Icon
                        icon={showConfirmPass ? "lucide:eye" : "lucide:eye-off"}
                        height={24}
                        width={24}
                        color="#212121"
                        strokeOpacity={0.6}
                      />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {confirmPassword !== "" && confirmPassword !== password ? (
              <p className="error-password">Password doesn’t match</p>
            ) : null}
          </div>

          <div className="mob-support">
            <p
              style={{
                fontSize: "14px",
                color: "#212121",
                lineHeight: "26px",
                marginBottom: "6px",
              }}
            >
              New Password must have:
            </p>
            <p
              style={{
                ...popoverOption,
                color: hasEightCharacters(password) ? "#027A48" : "#BA0000",
              }}
            >
              <Icon
                icon={hasEightCharacters(password) ? "charm:tick" : "lucide:x"}
                height={16}
                width={16}
                color={hasEightCharacters(password) ? "#027A48" : "#ba0000"}
              />
              At least 8 characters
            </p>
            <p
              style={{
                ...popoverOption,
                color: hasUpperAndLowerCase(password) ? "#027A48" : "#BA0000",
              }}
            >
              <Icon
                icon={hasUpperAndLowerCase(password) ? "charm:tick" : "lucide:x"}
                height={16}
                width={16}
                color={hasUpperAndLowerCase(password) ? "#027A48" : "#ba0000"}
              />
              Uppercase and lowercase character
            </p>
            <p
              style={{
                ...popoverOption,
                color: hasSpecialCharacter(password) ? "#027A48" : "#BA0000",
              }}
            >
              <Icon
                icon={hasSpecialCharacter(password) ? "charm:tick" : "lucide:x"}
                height={16}
                width={16}
                color={hasSpecialCharacter(password) ? "#027A48" : "#ba0000"}
              />
              One special character (@!#$%)
            </p>
            <p
              style={{
                ...popoverOption,
                color: hasNumber(password) ? "#027A48" : "#BA0000",
              }}
            >
              <Icon
                icon={hasNumber(password) ? "charm:tick" : "lucide:x"}
                height={16}
                width={16}
                color={hasNumber(password) ? "#027A48" : "#ba0000"}
              />
              One Number (1-9)
            </p>
          </div>
        </div>
      </div>

      <LoadingButton
        className="update-button"
        loading={loading}
        loadingPosition="end"
        variant="contained"
        disabled={
          oldPassword === "" ||
          password !== confirmPassword ||
          passwordStrength.num !== 4
        }
        onClick={changePasswordHandler}
      >
        Update
      </LoadingButton>

      <EcnPopover
        id="password-popover"
        open={open}
        anchorEl={anchorEl}
        sx={{
          marginLeft: "85px",
        }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        slotProps={{
          paper: {
            sx: { borderRadius: "6px", boxShadow: "none", overflow: "visible" },
          },
        }}
        disableAutoFocus={true}
        disableEnforceFocus={true}
      >
        <p
          style={{
            fontSize: "14px",
            color: "#212121",
            lineHeight: "26px",
            marginBottom: "6px",
          }}
        >
          Password must have:
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasEightCharacters(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasEightCharacters(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasEightCharacters(password) ? "#027A48" : "#ba0000"}
          />
          At least 8 characters
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasUpperAndLowerCase(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasUpperAndLowerCase(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasUpperAndLowerCase(password) ? "#027A48" : "#ba0000"}
          />
          Uppercase and lowercase character
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasSpecialCharacter(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasSpecialCharacter(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasSpecialCharacter(password) ? "#027A48" : "#ba0000"}
          />
          One special character (@!#$%)
        </p>
        <p
          style={{
            ...popoverOption,
            color: hasNumber(password) ? "#027A48" : "#BA0000",
          }}
        >
          <Icon
            icon={hasNumber(password) ? "charm:tick" : "lucide:x"}
            height={16}
            width={16}
            color={hasNumber(password) ? "#027A48" : "#ba0000"}
          />
          One Number (1-9)
        </p>
      </EcnPopover>
    </div>
  );
};

export default PasswordView;

const popoverOption = {
  fontSize: "14px",
  lineHeight: "26px",
  display: "flex",
  alignItems: "center",
  gap: "4px",
};
