import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const patchUserDetails = createAsyncThunk(
  "patchUserDetails",
  async (user: any) => {
    const { data } = await axios.patch(
      `${GATEWAY_BASE + AUTH_BASE}/v1/editBasicDetails`,
      user
    );
    return data;
  }
);

const patchUserDetailsSlice = createSlice({
  name: "patchUserDetails",
  initialState: {
    loading: false,
    patchResponse: null,
    error: false,
    success: false,
  },
  reducers: {
    resetPatchUser: (state) => {
      state.loading = false;
      state.patchResponse = null;
      state.error = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(patchUserDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(patchUserDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.patchResponse = action.payload;
      state.success = true;
    });
    builder.addCase(patchUserDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });
  },
});

export const { resetPatchUser } = patchUserDetailsSlice.actions;

export default patchUserDetailsSlice.reducer;
