import getAccountInfoSlice from "./getAccountInfoSlice";
import b2bUserDetailsSlice from "./b2bUserDetailsSlice";
import userSigninSlice from "./userSigninSlice";
import userSignoutSlice from "./userSignoutSlice";
import b2cUserDetailsSlice from "./b2cUserDetailsSlice";
import userDetailsSlice from "./userDetailsSlice";
import getAdminDetailsSlice from "./getAdminDetailsSlice";
import patchUserDetailsSlice from "./patchUserDetailsSlice";
import postUserProfileImageSlice from "./postUserProfileImageSlice";

export const allUserSlices = {
  userDetails: userDetailsSlice,
  userSignIn: userSigninSlice,
  signOut: userSignoutSlice,
  getAccountInfo: getAccountInfoSlice,
  getAdminDetails: getAdminDetailsSlice,
  patchUserDetails: patchUserDetailsSlice,
  postUserProfileImage: postUserProfileImageSlice,
};
