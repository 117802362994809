import React from "react";
import { Box, Popover, PopoverProps } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "1.5px solid rgba(0, 0 ,0, 0.2)",
    padding: "15px 17px",
    background: "#ffffff",
    color: "#212121",
    maxWidth: "314px",
    borderRadius: "6px",
    overflow: "visible",
    position: "relative", // Ensure the popover is positioned relative to its arrow
    "&::before": {
      content: '""', // Empty string for the content
      position: "absolute",
      top: "calc(50% - 10px)", // Vertically center the arrow
      left: "-12px", // Position to the left of the popover
      border: "1.5px solid rgba(0, 0, 0, .2)",
      borderTop: "none",
      borderRight: "none",
      zIndex: 1,
      transform: "rotate(45deg)",
      height: "19.7px",
      width: "19.7px",
      background: "#ffffff",
    },
  },
}));

const EcnPopover = (props: PopoverProps) => {
  const classes = useStyles();
  return (
    <Popover {...props}>
      <Box className={classes.paper}>{props.children}</Box>
    </Popover>
  );
};

export default EcnPopover;
