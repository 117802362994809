import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const deleteSecondaryEmail = createAsyncThunk(
  "deleteSecondaryEmail",
  async (email: string) => {
    const { data } = await axios.delete(
      `${GATEWAY_BASE + AUTH_BASE}/v1/deleteSecondaryEmail`,
      { data: { email } }
    );
    return data;
  }
);

const deleteSecondaryEmailSlice = createSlice({
  name: "deleteSecondaryEmail",
  initialState: {
    loading: false,
    deleteSecondaryEmail: null as any,
    error: false,
    success: false,
  },
  reducers: {
    resetDeleteSecondary: (state) => {
      state.loading = false;
      state.deleteSecondaryEmail = null;
      state.error = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteSecondaryEmail.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(deleteSecondaryEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.deleteSecondaryEmail = action.payload;
    });
    builder.addCase(deleteSecondaryEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetDeleteSecondary } = deleteSecondaryEmailSlice.actions;

export default deleteSecondaryEmailSlice.reducer;
