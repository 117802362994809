import React, { useEffect } from "react";
import layoutOne from "../components/layouts/layoutOne";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getSubscriptionList } from "../redux/slices/subscriptionSlices/getSubscriptionListSlice";
import { IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import moment from "moment";
import SubCard from "../components/Subscription/SubCard";

const Subscriptions = () => {
  const dispatch = useAppDispatch();

  const { subList } = useAppSelector((state) => state.getSubscriptionList);

  useEffect(() => {
    dispatch(getSubscriptionList());
  }, []);

  console.log("subList", subList);
  return (
    <div className="subscriptions">
      <h5>Subscriptions</h5>

      <div className="subs-list">
        {subList?.map((sub: any, index: number) => (
          <SubCard sub={sub} key={index} />
        ))}
      </div>
    </div>
  );
};

export default layoutOne(Subscriptions);
