import deleteSecondaryEmailSlice from "./deleteSecondaryEmailSlice";
import getSessionsSlice from "./getSessionsSlice";
import postAddSecondaryEmailSlice from "./postAddSecondaryEmailSlice";
import postChangePrimaryEmailSlice from "./postChangePrimaryEmailSlice";
import postLogoutSessionSlice from "./postLogoutSessionSlice";
import postSupportRequestSlice from "./postSupportRequestSlice";
import postUpdatePasswordSlice from "./postUpdatePasswordSlice";
import postVerifySecondaryEmailSlice from "./postVerifySecondaryEmailSlice";

export const allSecuritySlices = {
  getSessions: getSessionsSlice,
  postLogoutSession: postLogoutSessionSlice,
  postUpdatePassword: postUpdatePasswordSlice,
  postSupportRequest: postSupportRequestSlice,
  deleteSecondaryEmail: deleteSecondaryEmailSlice,
  postAddSecondaryEmail: postAddSecondaryEmailSlice,
  postChangePrimaryEmail: postChangePrimaryEmailSlice,
  postVerifySecondaryEmail: postVerifySecondaryEmailSlice,
};
