export const strongPassword = new RegExp(
  "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
);

// export const correctEmail = new RegExp(
//   "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$"
// );
export const correctEmail = new RegExp(
  "^[a-zA-Z0-9.!$%&'*\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$"
);


export const correctUrl = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
  "i"
);

export const hasEightCharacters = (password: string) =>
  new RegExp("^(?=.{8,})").test(password);

export const hasUpperAndLowerCase = (password: string) =>
  new RegExp(/^(?=.*[a-z])(?=.*[A-Z]).*$/).test(password);

export const hasNumber = (password: string) =>
  new RegExp(/^(?=.*[1-9]).*$/).test(password);

export const hasSpecialCharacter = (password: string) =>
  new RegExp(/^(?=.*[!@#$%^&*(),.?":{}|<>]).*$/).test(password);
