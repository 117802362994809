// redux/slices/userSlices/userDetailsSlice.ts
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";
import { RootState } from "../../store";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";
const CORE_BASE = process.env.REACT_APP_CORE_BASE || "";

export const userDetails = createAsyncThunk(
  "userDetails",
  async (_, { getState }) => {
    const state = getState() as RootState;
    const { accountInfo } = state.getAccountInfo;

    if (!accountInfo || !accountInfo.available_profiles) {
      throw new Error("No account info available");
    }

    const b2bUserId = accountInfo.available_profiles["b2b-user-id"];
    const ecnUserId = accountInfo.available_profiles["ecn-user-id"];

    if (b2bUserId) {
      const { data } = await axios.get(`${GATEWAY_BASE + ADMIN_BASE}/getMyInfo`);
      return { ...data, b2bUser: true };
    } else if (ecnUserId) {
      const { data } = await axios.get(`${GATEWAY_BASE + CORE_BASE}/api/v1/user/me`);
      return { ...data, b2bUser: false };
    } else {
      throw new Error("No valid user ID found");
    }
  }
);

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState: {
    userInfo: localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : null,
    loading: false,
    isB2BUser: localStorage.getItem("isB2BUser")
      ? JSON.parse(localStorage.getItem("isB2BUser")!)
      : false,
    error: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.userInfo = action.payload;
        state.isB2BUser = action.payload.b2bUser;

        localStorage.setItem("userInfo", JSON.stringify(action.payload));
        localStorage.setItem("isB2BUser", JSON.stringify(action.payload.b2bUser));
      })
      .addCase(userDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userDetailsSlice.reducer;
