import React, { useEffect, useState } from "react";
import EcnButton from "../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Chip, IconButton } from "@mui/material";
import EcnInput from "../StyledComponents/EcnInput";
import OTPInput from "react-otp-input";
import {
  postAddSecondaryEmail,
  resetAddSecondary,
} from "../../redux/slices/securitySlices/postAddSecondaryEmailSlice";
import { correctEmail } from "../../helpers/Validations/BasicValidations";
import {
  postVerifySecondaryEmail,
  resetVerifySecondary,
} from "../../redux/slices/securitySlices/postVerifySecondaryEmailSlice";
import { enqueueSnackbar } from "notistack";
import { userDetails } from "../../redux/slices/userSlices/userDetailsSlice";
import EmailRow from "./Sub/EmailRow";

interface ViewProps {
  drawerCloseHandler: () => void;
}

const EmailView = ({ drawerCloseHandler }: ViewProps) => {
  const dispatch = useAppDispatch();

  const { userInfo } = useAppSelector((state) => state.userDetails);
  const { addSecondaryEmail, loading: addLoading } = useAppSelector(
    (state) => state.postAddSecondaryEmail
  );
  const {
    error,
    verifySecondaryEmail,
    loading: verifyLoading,
  } = useAppSelector((state) => state.postVerifySecondaryEmail);

  const [editView, setEditView] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [resendTime, setResendTime] = useState<number>(60);

  useEffect(() => {
    let intervalId: any;

    if (resendTime > 0) {
      intervalId = setInterval(() => {
        setResendTime((prev) => (prev === 0 ? 0 : prev - 1));
      }, 1000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [resendTime]);

  useEffect(() => {
    if (verifySecondaryEmail) {
      setEditView(false);
      setNewEmail("");
      setOtp("");
      dispatch(userDetails());
      dispatch(resetAddSecondary());
      dispatch(resetVerifySecondary());
      enqueueSnackbar("Email added successfully!", {
        variant: "success",
      });
    }
  }, [verifySecondaryEmail]);

  const otpChangeHandler = (otp: any) => {
    const upOtp = otp.toUpperCase();
    setOtp(upOtp);
  };

  const verifyOtpHandler = () => {
    dispatch(postVerifySecondaryEmail(otp));
  };

  const verifyEmailHandler = () => {
    dispatch(postAddSecondaryEmail(newEmail));
  };

  const closeEditViewHandler = () => {
    setNewEmail("");
    setOtp("");
    setEditView(false);
    dispatch(resetAddSecondary());
    dispatch(resetVerifySecondary());
  };

  return (
    <div className="email-view">
      <div className="container">
        <EcnButton
          startIcon={
            <Icon icon="material-symbols:arrow-back" height={24} width={24} />
          }
          color="secondary"
          className="back-btn"
          onClick={drawerCloseHandler}
        >
          Email Management
        </EcnButton>

        <div className="all-emails">
          <div className="email">
            <p className="id">{userInfo?.email}</p>
            <Chip label="Primary Email Address" className="primary-chip" />
          </div>

          {userInfo?.secondary_emails?.map((sEmail: string) => (
            <EmailRow email={sEmail} />
          ))}

          {userInfo?.secondary_emails?.length > 0 && !editView && (
            <p className="note">
              <span>Note:</span> You can only use your{" "}
              <span>primary email address</span> to sign in or for any other kind of
              authentication.
            </p>
          )}

          {editView ? (
            <div className="email-edit">
              <div className="wrapper">
                <div className="field">
                  <label htmlFor="">Enter email address</label>
                  <EcnInput
                    className="ecn-input"
                    value={newEmail}
                    onChange={(e) => setNewEmail(e.target.value)}
                    error={newEmail !== "" && !correctEmail.test(newEmail)}
                    disabled={addSecondaryEmail}
                  />
                </div>

                <IconButton onClick={closeEditViewHandler}>
                  <Icon icon="ic:baseline-close" />
                </IconButton>
              </div>
              {addSecondaryEmail ? (
                <div className="otp-wrapper">
                  <p className="otp-label">Enter OTP</p>
                  <OTPInput
                    value={otp}
                    onChange={(val) => otpChangeHandler(val)}
                    numInputs={6}
                    renderInput={(props, index) => (
                      <input
                        {...props}
                        className={`otp-input ${
                          otp.length > index ? "data-entered" : ""
                        }`}
                      />
                    )}
                    containerStyle="otp-container"
                    inputStyle={
                      error
                        ? {
                            background: "transparent",
                            border: "1px solid #ba0000",
                            fontSize: "1.5rem",
                          }
                        : verifySecondaryEmail
                        ? {
                            background: "transparent",
                            border: "1px solid #027A48",
                            fontSize: "1.5rem",
                          }
                        : {
                            fontSize: "1.5rem",
                          }
                    }
                  />
                  <p className="resend">
                    Didn’t receive OTP ?{" "}
                    {resendTime > 0 ? (
                      <span>Resend in {resendTime} s</span>
                    ) : (
                      <span onClick={verifyEmailHandler}>Resend</span>
                    )}
                  </p>

                  <EcnButton
                    variant="contained"
                    onClick={verifyOtpHandler}
                    className="verify-btn"
                    disabled={otp.length !== 6 || verifyLoading}
                  >
                    Add Email
                  </EcnButton>
                </div>
              ) : (
                <EcnButton
                  variant="contained"
                  onClick={verifyEmailHandler}
                  className="verify-btn"
                  disabled={
                    newEmail === "" || !correctEmail.test(newEmail) || addLoading
                  }
                >
                  Verify
                </EcnButton>
              )}
            </div>
          ) : (
            <EcnButton
              variant="outlined"
              color="secondary"
              startIcon={<Icon icon="lucide:plus" />}
              className="add-btn"
              onClick={() => setEditView(true)}
            >
              Add new email address
            </EcnButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailView;
