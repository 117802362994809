import { useEffect, useState } from "react";
import EcnButton from "../StyledComponents/EcnButton";
import { Icon } from "@iconify/react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSessions } from "../../redux/slices/securitySlices/getSessionsSlice";
import { postLogoutSession } from "../../redux/slices/securitySlices/postLogoutSessionSlice";

import moment from "moment";
import { CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";

interface ViewProps {
  drawerCloseHandler: () => void;
}

const SessionView = ({ drawerCloseHandler }: ViewProps) => {
  const dispatch = useAppDispatch();

  const { sessionData } = useAppSelector((state) => state.getSessions);
  const { logoutResponse } = useAppSelector((state) => state.postLogoutSession);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getSessions());
  }, []);

  useEffect(() => {
    if (logoutResponse) {
      dispatch(getSessions());
      setLoading(false);
      enqueueSnackbar("Session Log out successfully!", {
        variant: "success",
      });
    }
  }, [logoutResponse]);

  const logoutHandler = (sessionId: string) => {
    dispatch(postLogoutSession(sessionId));
    setLoading(true);
  };

  return (
    <div className="session-view">
      <EcnButton
        startIcon={
          <Icon icon="material-symbols:arrow-back" height={24} width={24} />
        }
        color="secondary"
        className="back-btn"
        onClick={drawerCloseHandler}
      >
        Active Sessions
      </EcnButton>

      <div className="session-list">
        {sessionData?.map((session: any) => (
          <div className="session">
            <div className="body">
              {session.user_agent?.Mobile ? (
                <Icon
                  icon="lucide:smartphone"
                  height={24}
                  width={24}
                  strokeOpacity={0.6}
                  strokeWidth={2}
                />
              ) : (
                <Icon
                  icon="lucide:monitor-speaker"
                  height={24}
                  width={24}
                  strokeOpacity={0.6}
                  strokeWidth={2}
                />
              )}
              <div className="details">
                <p className="name">{session.user_agent?.Name}</p>
                <div className="sub">
                  <p className="location">
                    {session.ip_config?.city + ", " + session.ip_config?.country}
                  </p>
                  <div className="circle" />
                  <p className="time">
                    {moment.unix(session.last_access).fromNow()}
                  </p>
                </div>
              </div>
            </div>

            <EcnButton
              variant="outlined"
              color="secondary"
              className="logout-btn"
              onClick={() => logoutHandler(session.session_id)}
            >
              Log Out
            </EcnButton>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SessionView;
