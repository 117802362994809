import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { allUserSlices } from "./slices/userSlices/allUserSlices";
import { allHeaderSlices } from "./slices/headerSlices/allHeaderSlices";
import { allSecuritySlices } from "./slices/securitySlices/allSecuritySlices";
import { allSubscriptionSlices } from "./slices/subscriptionSlices/allSubscriptionSlices";

export const store = configureStore({
  reducer: {
    ...allUserSlices,
    ...allHeaderSlices,
    ...allSecuritySlices,
    ...allSubscriptionSlices,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
