import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useAppSelector } from "../redux/hooks";

import { Icon } from "@iconify/react";
import { Drawer } from "@mui/material";

import { enqueueSnackbar } from "notistack";

import SessionView from "../components/Security/SessionView";
import PasswordView from "../components/Security/PasswordView";

import layoutOne from "../components/layouts/layoutOne";
import EmailView from "../components/Security/EmailView";

const Security = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const view = searchParams.get("view") || "";

  const { isB2BUser } = useAppSelector((state) => state.userDetails);
  const { passwordResponse } = useAppSelector((state) => state.postUpdatePassword);

  const [sessionView, setSessionView] = useState(false);
  const [passwordView, setPasswordView] = useState(false);
  const [emailView, setEmailView] = useState(false);

  useEffect(() => {
    if (view === "session") setSessionView(true);
    else if (view === "password") setPasswordView(true);
    else if (view === "email") setEmailView(true);
  }, [view]);

  useEffect(() => {
    if (passwordResponse) {
      drawerCloseHandler();
      enqueueSnackbar("Password updated successfully!", {
        variant: "success",
      });
    }
  }, [passwordResponse]);

  const drawerCloseHandler = () => {
    if (view === "session") setSessionView(false);
    else if (view === "password") setPasswordView(false);
    else if (view === "email") setEmailView(false);

    navigate("/security");
  };
  return (
    <div className="security">
      <h5>Security Settings</h5>
      <div className="setting-list">
        <div className="container">
          {!isB2BUser && (
            <div className="option" onClick={() => navigate("?view=email")}>
              <p className="name">Email Management</p>
              <Icon
                icon="material-symbols:arrow-forward"
                className="security-icon"
              />
            </div>
          )}

          <div className="option" onClick={() => navigate("?view=password")}>
            <p className="name">Password Management</p>
            <Icon icon="material-symbols:arrow-forward" className="security-icon" />
          </div>

          <div className="option" onClick={() => navigate("?view=session")}>
            <p className="name">Active Sessions</p>
            <Icon icon="material-symbols:arrow-forward" className="security-icon" />
          </div>

          {/*<div className="option">
            <p className="name">Two Factor Authentication(2FA)</p>
            <Icon icon="material-symbols:arrow-forward" className="security-icon" />
          </div>*/}
        </div>
      </div>

      <Drawer
        anchor="right"
        open={emailView}
        onClose={drawerCloseHandler}
        className="email-drawer"
      >
        <EmailView drawerCloseHandler={drawerCloseHandler} />
      </Drawer>

      <Drawer
        anchor="right"
        open={passwordView}
        onClose={drawerCloseHandler}
        className="password-drawer"
      >
        <PasswordView drawerCloseHandler={drawerCloseHandler} />
      </Drawer>

      <Drawer
        anchor="right"
        open={sessionView}
        onClose={drawerCloseHandler}
        className="session-drawer"
      >
        <SessionView drawerCloseHandler={drawerCloseHandler} />
      </Drawer>
    </div>
  );
};

export default layoutOne(Security);
