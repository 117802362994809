import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const SUBS_BASE = process.env.REACT_APP_SUBSCRIPTION_BASE || "";

export const getSubscriptionList = createAsyncThunk(
  "getSubscriptionList",
  async () => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + SUBS_BASE}/api/subscriptions/v1`
    );
    return data;
  }
);

const getSubscriptionListSlice = createSlice({
  name: "getSubscriptionList",
  initialState: {
    loading: false,
    subList: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubscriptionList.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSubscriptionList.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.subList = action.payload;
    });
    builder.addCase(getSubscriptionList.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getSubscriptionListSlice.reducer;
