import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { userDetails } from "./redux/slices/userSlices/userDetailsSlice";
import { getAccountInfo } from "./redux/slices/userSlices/getAccountInfoSlice";

import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import { MaterialDesignContent, SnackbarProvider, closeSnackbar } from "notistack";
import { styled } from "@mui/material";

import Profile from "./pages/Profile";
import SignIn from "./pages/SignIn";
import Security from "./pages/Security";
import { Icon } from "@iconify/react";
import Help from "./pages/Help";
import Billings from "./pages/Billings";
import Subscriptions from "./pages/Subscriptions";

const SIGNIN_REDIRECT = process.env.REACT_APP_AUTH_REDIRECT;

const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-success": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "#2BBA1D",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },

  "&.notistack-MuiContent-error": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "#BA0000",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-warning": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    padding: "20px 23px 16px 21px",
    minWidth: "442px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "rgba(254, 152, 0, 1)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-info": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    fontWeight: "500",
    minWidth: "442px",
    padding: "20px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
    svg: {
      color: "rgba(33, 33, 33, 1)",
      transform: "rotate(180deg)",
      width: "25px",
      height: "25px",
      marginInlineEnd: "13px",
    },
  },
  "&.notistack-MuiContent-default": {
    borderRadius: "12px",
    background: "#fff",
    color: "rgba(33, 33, 33, 0.80)",
    fontSize: "14px",
    minWidth: "442px",
    fontWeight: "500",
    padding: "12px 23px 16px 21px",
    "&.notistack-snackbar": {
      gap: "13px",
    },
  },
}));

function App() {
  const dispatch = useAppDispatch();

  const { accountInfo, loading, errorData } = useAppSelector(
    (state) => state.getAccountInfo
  );

  useEffect(() => {
    dispatch(getAccountInfo());
  }, []);

  useEffect(() => {
    if (accountInfo) {
      dispatch(userDetails());
    }
  }, [accountInfo, dispatch]);

  useEffect(() => {
    if (
      !accountInfo &&
      errorData?.message === "401" &&
      window.location.pathname !== "/signin" &&
      window.location.pathname !== "/signup/details"
    ) {
      window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`;
    }
  }, [errorData, accountInfo]);

  if (loading && window.location.pathname !== "/signin")
    return <div>Loading...</div>;
  if (errorData && window.location.pathname !== "/signin") return <div>Error</div>;

  return (
    <Router>
      <SnackbarProvider
        maxSnack={4}
        Components={{
          success: StyledMaterialDesignContent,
          error: StyledMaterialDesignContent,
          warning: StyledMaterialDesignContent,
          info: StyledMaterialDesignContent,
          default: StyledMaterialDesignContent,
        }}
        iconVariant={{
          success: <Icon icon="lucide:check-circle-2" />,
          error: <Icon icon="lucide:x-circle" />,
          warning: <Icon icon="lucide:alert-circle" />,
          info: <Icon icon="lucide:alert-circle" />,
        }}
        action={(snackbarId: any) => (
          <Icon
            onClick={() => closeSnackbar(snackbarId)}
            icon="basil:cross-solid"
            className="snackbar-cross"
          />
        )}
      >
        <Routes>
          <Route path="/" element={<Profile />} />
          {process.env.REACT_APP_API_GATEWAY_BASE ===
            "https://api.dev.ecndev.io" && (
            <Route path="/signin" element={<SignIn />} />
          )}

          <Route path="/security" element={<Security />} />
          <Route path="/support" element={<Help />} />
          <Route path="/billings" element={<Billings />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
        </Routes>
      </SnackbarProvider>
    </Router>
  );
}

export default App;
