import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { userSignout } from "../../redux/slices/userSlices/userSignoutSlice";
import { getProductList } from "../../redux/slices/headerSlices/getProductListSlice";

import { Icon } from "@iconify/react";
import { Avatar, Badge, IconButton, Menu, Drawer } from "@mui/material";

import EcnButton from "../StyledComponents/EcnButton";
import { Link, useLocation } from "react-router-dom";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;
const SIGNIN_REDIRECT = process.env.REACT_APP_AUTH_REDIRECT;

const Header = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { userInfo, isB2BUser } = useAppSelector((state) => state.userDetails);

  const { signOut } = useAppSelector((state) => state.signOut);
  const { productList } = useAppSelector((state) => state.getProductList);
  //const { notificationList } = useAppSelector((state) => state.getNotification);

  const [productAnchorEl, setProductAnchorEl] = useState<null | SVGSVGElement>(null);
  const openProduct = Boolean(productAnchorEl);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [click, setClick] = useState(false);
  const [openMobMenu, setOpenMobMenu] = useState(false);
  const [openNotification, setOpenNotification] = useState(false);
  const [totalNotification, setTotalNotification] = useState();
  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);

  useEffect(() => {
    dispatch(getProductList());
  }, []);

  /*useEffect(() => {
    if (notificationList) setTotalNotification(notificationList.unread_count);
  }, [notificationList]);*/

  useEffect(() => {
    if (signOut && click) {
      if (window.location.pathname !== "/signin")
        window.location.href = `${SIGNIN_REDIRECT}?origin=${window?.location.href}`;
    }
  }, [signOut]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOutHandler = () => {
    setAnchorEl(null);
    setClick(true);
    dispatch(userSignout(userInfo?.email));
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // Header menu functions
  const navigateProductHandler = (product: any) => {
    setProductAnchorEl(null);
    if (product.selected) return;
    else window.open(product.url, "_blank");
  };

  const openNotificationHandler = (event: any) => {
    //dispatch(getUnreadNotification("4"));
    setAnchorElNotification(event.currentTarget);
    setOpenNotification(true);
  };

  // Filter the url array based on the isB2BUser flag
  const filteredUrl = url.filter((item) => {
    if (isB2BUser && (item.title === "Billings" || item.title === "Subscriptions")) {
      return false;
    }
    return true;
  });

  return (
    <div className="header">
      <div className="left">
        <Icon
          icon="lucide:menu"
          className="mob-menu"
          onClick={() => setOpenMobMenu(true)}
        />
        <Drawer
          open={openMobMenu}
          onClose={() => setOpenMobMenu(false)}
          className="mob-menu"
        >
          <div>
            <div className="head">
              <img src="../new_logo_black.png" alt="" />
              <Icon icon="lucide:x" onClick={() => setOpenMobMenu(false)} />
            </div>
            <div className="user">
              {userInfo?.display_picture ? (
                <Avatar
                  src={
                    userInfo?.display_picture === ""
                      ? `${IMG_BASE}/common/MicrosoftTeams-image (4).png`
                      : userInfo?.display_picture
                  }
                  alt="E"
                  className="avatar"
                />
              ) : (
                <Avatar
                  sx={{
                    background: "rgba(0, 52, 187, 0.13)",
                    color: "#0034BB",
                  }}
                  className="avatar"
                >
                  {userInfo?.first_name?.[0] + userInfo?.last_name?.[0]}
                </Avatar>
              )}
              <p className="account">My Account</p>
            </div>

            <div className="list">
              {filteredUrl?.map((item: any) => (
                <Link
                  to={item.urlLink}
                  className={
                    location.pathname === item.urlLink
                      ? "top-head active"
                      : "top-head"
                  }
                >
                  {item?.icon}
                  {item?.title}
                </Link>
              ))}
            </div>
          </div>

          <div className="signout-wrapper">
            <Icon icon="lucide:log-out" className="signout-icon" />
            <p className="signout-text">Sign Out</p>
          </div>
        </Drawer>
        <img src="../new_logo_black.png" alt="" />
      </div>
      <div className="right">
        <ul>
          {/* 
          <li onClick={openNotificationHandler}>
            {totalNotification ? (
              <Badge
                sx={{
                  "& .MuiBadge-badge": {
                    color: "#fff",
                    backgroundColor: "#0034BB",
                  },
                }}
                className="notification"
                badgeContent={totalNotification}
                max={100}
              >
                <Icon
                  icon="lucide:bell"
                  className={`icon ${openNotification ? "selected" : ""}`}
                  width="23"
                  height="23"
                />
              </Badge>
            ) : (
              <Icon
                icon="lucide:bell"
                className={`icon ${openNotification ? "selected" : ""}`}
              />
            )}
          </li>
          */}

          {isB2BUser ? (
            <li>
              <Icon
                id="product-button"
                aria-controls={openProduct ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openProduct ? "true" : undefined}
                onClick={(e) => setProductAnchorEl(e.currentTarget)}
                icon="lucide:grip"
                className={`icon ${openProduct ? "selected" : ""}`}
              />

              <Menu
                id="product-menu"
                anchorEl={productAnchorEl}
                open={openProduct}
                onClose={() => setProductAnchorEl(null)}
                MenuListProps={{
                  "aria-labelledby": "product-button",
                }}
                className="product-menu"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
              >
                <h5>Products</h5>
                <div className="products-wrapper">
                  {productList?.map((product: any) => (
                    <div
                      className="product"
                      onClick={() => navigateProductHandler(product)}
                    >
                      <div
                        className={`icon-wrapper ${
                          product.selected ? "selected" : ""
                        }`}
                        onClick={() => navigateProductHandler(product)}
                      >
                        <Icon
                          icon={product.icon}
                          className="icon"
                          height={24}
                          width={24}
                        />
                      </div>
                      <div className="text-wrapper">
                        <p
                          className={`name ${
                            product.selected ? "selected-name" : ""
                          }`}
                        >
                          {product.short_name}
                        </p>
                        <p className="desc">{product.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </Menu>
            </li>
          ) : null}
          <li>
            <IconButton
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              style={{ maxWidth: "40px", maxHeight: "40px" }}
            >
              {isB2BUser ? (
                userInfo?.display_picture ? (
                  <Avatar
                    src={
                      userInfo?.display_picture === ""
                        ? `${IMG_BASE}/common/MicrosoftTeams-image (4).png`
                        : userInfo?.display_picture
                    }
                    alt="E"
                    className="avatar"
                  />
                ) : (
                  <Avatar
                    sx={{
                      background: "rgba(0, 52, 187, 0.13)",
                      color: "#0034BB",
                    }}
                  >
                    {userInfo?.first_name?.[0] + userInfo?.last_name?.[0]}
                  </Avatar>
                )
              ) : userInfo?.display_pictures?.[0] ? (
                <Avatar
                  src={
                    userInfo?.display_pictures?.[0] === ""
                      ? `${IMG_BASE}/common/MicrosoftTeams-image (4).png`
                      : userInfo?.display_pictures?.[0]
                  }
                  alt="E"
                  className="avatar"
                />
              ) : (
                <Avatar
                  sx={{
                    background: "rgba(0, 52, 187, 0.13)",
                    color: "#0034BB",
                  }}
                >
                  {userInfo?.first_name?.[0] + userInfo?.last_name?.[0]}
                </Avatar>
              )}
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="signout-menu"
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ marginLeft: "-20px" }}
            >
              <div className="user-menu">
                <div className="user">
                  <div className="left">
                    {isB2BUser ? (
                      userInfo?.display_picture ? (
                        <Avatar
                          src={
                            userInfo?.display_picture === ""
                              ? `${IMG_BASE}/common/MicrosoftTeams-image (4).png`
                              : userInfo?.display_picture
                          }
                          alt="E"
                          className="avatar"
                        />
                      ) : (
                        <Avatar
                          sx={{
                            background: "rgba(0, 52, 187, 0.13)",
                            color: "#0034BB",
                          }}
                        >
                          {userInfo?.first_name?.[0] + userInfo?.last_name?.[0]}
                        </Avatar>
                      )
                    ) : userInfo?.display_pictures?.[0] ? (
                      <Avatar
                        src={
                          userInfo?.display_pictures?.[0] === ""
                            ? `${IMG_BASE}/common/MicrosoftTeams-image (4).png`
                            : userInfo?.display_pictures?.[0]
                        }
                        alt="E"
                        className="avatar"
                      />
                    ) : (
                      <Avatar
                        sx={{
                          background: "rgba(0, 52, 187, 0.13)",
                          color: "#0034BB",
                        }}
                      >
                        {userInfo?.first_name?.[0] + userInfo?.last_name?.[0]}
                      </Avatar>
                    )}
                  </div>

                  <div className="right">
                    <p className="name">
                      {userInfo?.first_name + " " + userInfo?.last_name}
                    </p>
                    <p className="email">{userInfo?.email}</p>
                  </div>
                </div>

                <div className="divider"></div>
                <div className="btn-wrapper" onClick={signOutHandler}>
                  <EcnButton color="secondary" className="signout-btn">
                    Sign Out
                  </EcnButton>
                </div>
              </div>
            </Menu>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Header;

const url = [
  {
    title: "Profile",
    icon: <Icon icon="lucide:circle-user" className="icons" />,
    urlLink: "/",
  },
  {
    title: "Security",
    urlLink: "/security",
    icon: <Icon icon="lucide:shield-check" className="icons" />,
  },
  {
    title: "Billings",
    urlLink: "/billings",
    icon: <Icon icon="lucide:receipt" className="icons" />,
  },
  {
    title: "Subscriptions",
    icon: <Icon icon="lucide:crown" className="icons" />,
    urlLink: "/subscriptions",
  },
  {
    title: "Help & support",
    icon: <Icon icon="lucide:help-circle" className="icons" />,
    urlLink: "/support",
  },
];
