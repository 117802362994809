import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const postChangePrimaryEmail = createAsyncThunk(
  "postChangePrimaryEmail",
  async (email: string) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/changePrimaryEmail`,
      { email }
    );
    return data;
  }
);

const postChangePrimaryEmailSlice = createSlice({
  name: "postChangePrimaryEmail",
  initialState: {
    loading: false,
    changePrimaryEmail: null as any,
    error: false,
    success: false,
  },
  reducers: {
    resetChangePrimary: (state) => {
      state.loading = false;
      state.changePrimaryEmail = null;
      state.error = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postChangePrimaryEmail.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(postChangePrimaryEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.changePrimaryEmail = action.payload;
    });
    builder.addCase(postChangePrimaryEmail.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const { resetChangePrimary } = postChangePrimaryEmailSlice.actions;

export default postChangePrimaryEmailSlice.reducer;
