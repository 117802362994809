import React, { useEffect, useState } from "react";

import layoutOne from "../components/layouts/layoutOne";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getAdminDetails } from "../redux/slices/userSlices/getAdminDetailsSlice";
import { Icon } from "@iconify/react";
import EcnInput from "../components/StyledComponents/EcnInput";
import EcnButton from "../components/StyledComponents/EcnButton";
import PhoneInput from "react-phone-input-2";
import { maskCountry } from "../helpers/mask";
import { postSupportRequest } from "../redux/slices/securitySlices/postSupportRequestSlice";
import { enqueueSnackbar } from "notistack";

const Help = () => {
  const dispatch = useAppDispatch();

  const { isB2BUser, userInfo } = useAppSelector((state) => state.userDetails);
  const { adminInfo } = useAppSelector((state) => state.getAdminDetails);
  const { supportResponse } = useAppSelector((state) => state.postSupportRequest);

  const [submitTouched, setSubmitTouched] = useState<boolean>(false);
  const [mobileError, setMobileError] = useState<boolean>(false);
  const [data, setData] = useState({
    name: "",
    mobile: "",
    email: "",
    alternate_email: "",
    subject: "",
    description: "",
  });

  useEffect(() => {
    if (isB2BUser) dispatch(getAdminDetails());
  }, [isB2BUser]);

  useEffect(() => {
    if (userInfo) {
      setData({
        ...data,
        name: userInfo.first_name + " " + userInfo.last_name,
        email: userInfo.email,
        mobile: userInfo.mobile,
      });
    }
  }, []);

  useEffect(() => {
    if (supportResponse && submitTouched) {
      enqueueSnackbar(
        "We've received your support request. We will contact you as soon as possible.",
        { variant: "success" }
      );
      setData({
        name: "",
        mobile: "",
        email: userInfo?.email,
        alternate_email: "",
        subject: "",
        description: "",
      });
      setSubmitTouched(false);
    }
  }, [supportResponse, submitTouched, userInfo]);

  const mobileChangeHandler = (val: any, country: any, e: any, formatVal: any) => {
    if (formatVal.length !== country?.format.length) {
      setMobileError(true);
    } else {
      setMobileError(false);
    }

    setData({ ...data, mobile: formatVal });
  };

  const changeHandler = (e: any) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submitHandler = () => {
    dispatch(postSupportRequest(data));
    setSubmitTouched(true);
  };

  return (
    <div className="help">
      <h5>Help & Support</h5>
      {isB2BUser ? (
        <div className="admin-info">
          <p className="heading">Contact Admin</p>

          <div className="field">
            <svg
              className="field-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <path
                d="M15 3H3C2.17157 3 1.5 3.67157 1.5 4.5V13.5C1.5 14.3284 2.17157 15 3 15H15C15.8284 15 16.5 14.3284 16.5 13.5V4.5C16.5 3.67157 15.8284 3 15 3Z"
                stroke="#0034BB"
                stroke-opacity="0.7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 5.25L9.7725 9.525C9.54095 9.67007 9.27324 9.74701 9 9.74701C8.72676 9.74701 8.45905 9.67007 8.2275 9.525L1.5 5.25"
                stroke="#0034BB"
                stroke-opacity="0.7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div className="details">
              <label htmlFor="">Email Address</label>
              <a href={`mailto:${adminInfo?.email}`} className="data">
                {adminInfo?.email}
              </a>
            </div>
          </div>

          <div className="field">
            <svg
              className="field-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g clip-path="url(#clip0_240_670)">
                <path
                  d="M16.5001 12.6901V14.9401C16.5009 15.1489 16.4581 15.3557 16.3745 15.5471C16.2908 15.7385 16.168 15.9103 16.0141 16.0515C15.8602 16.1927 15.6785 16.3002 15.4806 16.3671C15.2828 16.434 15.0731 16.4589 14.8651 16.4401C12.5572 16.1893 10.3403 15.4007 8.39257 14.1376C6.58044 12.9861 5.04407 11.4497 3.89257 9.63757C2.62506 7.68098 1.83625 5.45332 1.59007 3.13507C1.57133 2.92767 1.59598 2.71864 1.66245 2.52129C1.72892 2.32394 1.83575 2.14259 1.97615 1.98879C2.11654 1.83499 2.28743 1.7121 2.47792 1.62796C2.6684 1.54382 2.87433 1.50027 3.08257 1.50007H5.33257C5.69655 1.49649 6.04942 1.62538 6.32539 1.86272C6.60137 2.10006 6.78163 2.42966 6.83257 2.79007C6.92754 3.51012 7.10366 4.21712 7.35757 4.89757C7.45848 5.16602 7.48032 5.45776 7.4205 5.73823C7.36069 6.01871 7.22172 6.27616 7.02007 6.48007L6.06757 7.43257C7.13524 9.31023 8.68991 10.8649 10.5676 11.9326L11.5201 10.9801C11.724 10.7784 11.9814 10.6395 12.2619 10.5796C12.5424 10.5198 12.8341 10.5417 13.1026 10.6426C13.783 10.8965 14.49 11.0726 15.2101 11.1676C15.5744 11.219 15.9071 11.4025 16.145 11.6832C16.3828 11.9639 16.5092 12.3223 16.5001 12.6901Z"
                  stroke="#0034BB"
                  stroke-opacity="0.7"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_240_670">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div className="details">
              <label htmlFor="">Contact Number</label>
              {adminInfo?.mobile ? (
                <a href={`tel:${adminInfo?.mobile}`} className="data">
                  {adminInfo?.mobile}
                </a>
              ) : (
                <a className="data">NA</a>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="support-form">
          <h4 className="heading">Submit a Support Request</h4>
          <p className="sub-heading">
            Give us some context for your query, and we'll get back to you soon.
          </p>

          <div className="form">
            <div className="row">
              <div className="field">
                <label htmlFor="">Name*</label>
                <EcnInput
                  name="name"
                  className="ecn-input"
                  placeholder="Enter name"
                  value={data.name}
                  onChange={changeHandler}
                />
              </div>

              <div className="field">
                <label htmlFor="">Mobile Number (optional)</label>
                <PhoneInput
                  country={"us"}
                  preferredCountries={[
                    "gb",
                    "fr",
                    "es",
                    "it",
                    "cn",
                    "in",
                    "us",
                    "ca",
                  ]}
                  containerClass="mobile-input"
                  placeholder="Enter phone number"
                  value={data.mobile}
                  masks={maskCountry}
                  isValid={(inputNumber, country, countries) => {
                    const valid = countries.some((country: any) => {
                      return inputNumber.startsWith(country.dialCode);
                    });
                    if (!valid) setMobileError(true);
                    return true;
                  }}
                  onChange={(val, country, e, formatVal) => {
                    mobileChangeHandler(val, country, e, formatVal);
                  }}
                />
                {mobileError && data.mobile.length > 1 && (
                  <p className="mobile-error">Invalid Phone Number</p>
                )}
              </div>
            </div>

            {/* Row 2 */}
            <div className="row">
              <div className="field">
                <label htmlFor="">Email*</label>
                <EcnInput
                  disabled
                  name="email"
                  value={data.email}
                  className="ecn-input"
                  placeholder="Enter email address"
                />
              </div>

              <div className="field">
                <label htmlFor="">Alternate Email Address (optional)</label>
                <EcnInput
                  name="alternate_email"
                  value={data.alternate_email}
                  onChange={changeHandler}
                  className="ecn-input"
                  placeholder="Enter alternate email address"
                />
              </div>
            </div>

            <div className="field">
              <label htmlFor="">Subject*</label>
              <EcnInput
                name="subject"
                value={data.subject}
                onChange={changeHandler}
                className="ecn-input"
                placeholder="Enter Subject"
              />
            </div>

            <div className="field">
              <label htmlFor="">Description*</label>
              <EcnInput
                multiline
                rows={4}
                name="description"
                value={data.description}
                onChange={changeHandler}
                className="ecn-input textarea"
                placeholder="Elaborate on what is the problem"
              />
            </div>

            <div className="btn-wrapper">
              <EcnButton
                disabled={
                  data.name === "" ||
                  data.email === "" ||
                  data.subject.length < 3 ||
                  data.description.length < 10
                }
                variant="contained"
                className="submit-btn"
                onClick={submitHandler}
              >
                Submit
              </EcnButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default layoutOne(Help);
