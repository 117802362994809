import React, { useEffect, useState } from "react";
import layoutOne from "../components/layouts/layoutOne";
import { Icon } from "@iconify/react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { Button, Divider, Drawer, TextField } from "@mui/material";
import PhoneInput from "react-phone-input-2";

//import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import EcnInput from "../components/StyledComponents/EcnInput";
import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import EcnButton from "../components/StyledComponents/EcnButton";
import {
  patchUserDetails,
  resetPatchUser,
} from "../redux/slices/userSlices/patchUserDetailsSlice";
import { userDetails } from "../redux/slices/userSlices/userDetailsSlice";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import DropZone from "../components/Profile/DropZone";

const Profile = () => {
  const dispatch = useAppDispatch();

  const { userInfo, isB2BUser } = useAppSelector((state) => state.userDetails);
  const { success: patchSuccess } = useAppSelector(
    (state) => state.patchUserDetails
  );

  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [edit, setEdit] = useState(false);
  const [touched, setTouched] = useState(false);
  const [editView, setEditView] = useState(false);
  const [editData, setEditData] = useState({
    first_name: "",
    last_name: "",
    birthday: "",
    mobile: "",
    display_pictures: "",
  });

  useEffect(() => {
    if (userInfo) {
      setEditData({
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        birthday: userInfo?.birthday,
        mobile: userInfo?.mobile,
        display_pictures: userInfo?.display_pictures,
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (patchSuccess && touched) {
      dispatch(userDetails());
      setEditView(false);
      enqueueSnackbar("User details updated successfully!", {
        variant: "success",
      });
      setTouched(false);
      dispatch(resetPatchUser());
    }
  }, [patchSuccess, touched]);

  const changeHandler = (e: any) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };
  const birthdayHandler = (e: any) => {
    setEditData({ ...editData, birthday: e.toISOString() });
  };

  const cancelEditHandler = () => {
    setEditView(false);
    setEditData({
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      birthday: userInfo?.birthday,
      mobile: userInfo?.mobile,
      display_pictures: userInfo?.display_pictures,
    });
  };

  const saveChangesHandler = () => {
    setTouched(true);
    dispatch(patchUserDetails(editData));
  };

  console.log("us", userInfo);
  console.log(
    "img",
    isB2BUser
      ? userInfo?.display_picture ||
          "https://s1.ecnstatic.com/ecn/images/common/images.png"
      : userInfo?.display_pictures?.[1] ||
          "https://s1.ecnstatic.com/ecn/images/common/images.png"
  );
  return (
    <div className="profile">
      {editView ? (
        <div className="edit_user-card">
          <div className="img-wrapper">
            <img
              src={
                editData?.display_pictures?.[1] ||
                "https://s1.ecnstatic.com/ecn/images/common/images.png"
              }
              alt=""
            />
            <div className="edit-img" onClick={() => setOpen(true)}>
              <Icon
                icon="lucide:pencil"
                height={18}
                width={18}
                className="edit-icon"
              />
            </div>
          </div>

          <div className="text-fields">
            <div className="row">
              <div className="field">
                <p>First name*</p>
                <EcnInput
                  className="ecn-input"
                  placeholder="Enter first name"
                  name="first_name"
                  value={editData.first_name}
                  onChange={changeHandler}
                />
              </div>
              <div className="field">
                <p>Last name*</p>
                <EcnInput
                  className="ecn-input"
                  placeholder="Enter last name"
                  name="last_name"
                  value={editData.last_name}
                  onChange={changeHandler}
                />
              </div>
            </div>
            <div className="row">
              <div className="field">
                <p>Date of Birth*</p>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    value={editData.birthday}
                    onChange={(e: any) => birthdayHandler(e)}
                    maxDate={new Date()}
                    className="input-text"
                    disableHighlightToday={false} // Add the missing property
                    showDaysOutsideCurrentMonth={false} // Add the missing property
                    allowSameDateSelection={false} // Add the missing property
                    renderInput={(params) => (
                      <EcnInput className="ecn-input" {...params} />
                    )}
                  />
                </LocalizationProvider>
              </div>

              <div className="field">
                <p>Phone number*</p>
                <PhoneInput
                  country={"us"}
                  preferredCountries={[
                    "gb",
                    "fr",
                    "es",
                    "it",
                    "cn",
                    "in",
                    "us",
                    "ca",
                  ]}
                  containerClass="mobile-input"
                  placeholder="Enter phone number"
                  value={userInfo?.mobile}
                  disabled
                />
              </div>
            </div>

            <div className="field">
              <p>Email address*</p>
              <EcnInput
                className="ecn-input"
                placeholder="Enter email address"
                value={userInfo?.email}
                disabled
              />
            </div>
          </div>

          <div className="btn-group">
            <EcnButton variant="outlined" onClick={cancelEditHandler}>
              Cancel
            </EcnButton>
            <EcnButton variant="contained" onClick={saveChangesHandler}>
              Save Changes
            </EcnButton>
          </div>
        </div>
      ) : (
        <div className="user-card">
          {!isB2BUser ? (
            <Button
              className="edit-btn"
              startIcon={<Icon icon="lucide:pencil" height={18} width={18} />}
              onClick={() => setEditView(true)}
            >
              Edit
            </Button>
          ) : null}
          <div className="img-wrapper">
            <img
              src={
                isB2BUser
                  ? userInfo?.display_picture ||
                    "https://s1.ecnstatic.com/ecn/images/common/images.png"
                  : userInfo?.display_pictures?.[1] ||
                    "https://s1.ecnstatic.com/ecn/images/common/images.png"
              }
              alt=""
            />
          </div>
          <h4 className="name">
            {userInfo?.first_name + " " + userInfo?.last_name}
          </h4>
          <p className="designation">{userInfo?.designation}</p>

          <div className="all-fields">
            <div className="user-field">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                className="field-icon"
              >
                <path
                  d="M15 3H3C2.17157 3 1.5 3.67157 1.5 4.5V13.5C1.5 14.3284 2.17157 15 3 15H15C15.8284 15 16.5 14.3284 16.5 13.5V4.5C16.5 3.67157 15.8284 3 15 3Z"
                  stroke="#212121"
                  stroke-opacity="0.6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M16.5 5.25L9.7725 9.525C9.54095 9.67007 9.27324 9.74701 9 9.74701C8.72676 9.74701 8.45905 9.67007 8.2275 9.525L1.5 5.25"
                  stroke="#212121"
                  stroke-opacity="0.6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="details">
                <p className="label">Email Address</p>
                <p className="data">{userInfo?.email}</p>
              </div>
            </div>

            <div className="user-field">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                className="field-icon"
              >
                <g clip-path="url(#clip0_467_1521)">
                  <path
                    d="M16.5001 12.6901V14.9401C16.5009 15.1489 16.4581 15.3557 16.3745 15.5471C16.2908 15.7385 16.168 15.9103 16.0141 16.0515C15.8602 16.1927 15.6785 16.3002 15.4806 16.3671C15.2828 16.434 15.0731 16.4589 14.8651 16.4401C12.5572 16.1893 10.3403 15.4007 8.39257 14.1376C6.58044 12.9861 5.04407 11.4497 3.89257 9.63757C2.62506 7.68098 1.83625 5.45332 1.59007 3.13507C1.57133 2.92767 1.59598 2.71864 1.66245 2.52129C1.72892 2.32394 1.83575 2.14259 1.97615 1.98879C2.11654 1.83499 2.28743 1.7121 2.47792 1.62796C2.6684 1.54382 2.87433 1.50027 3.08257 1.50007H5.33257C5.69655 1.49649 6.04942 1.62538 6.32539 1.86272C6.60137 2.10006 6.78163 2.42966 6.83257 2.79007C6.92754 3.51012 7.10366 4.21712 7.35757 4.89757C7.45848 5.16602 7.48032 5.45776 7.4205 5.73823C7.36069 6.01871 7.22172 6.27616 7.02007 6.48007L6.06757 7.43257C7.13524 9.31023 8.68991 10.8649 10.5676 11.9326L11.5201 10.9801C11.724 10.7784 11.9814 10.6395 12.2619 10.5796C12.5424 10.5198 12.8341 10.5417 13.1026 10.6426C13.783 10.8965 14.49 11.0726 15.2101 11.1676C15.5744 11.219 15.9071 11.4025 16.145 11.6832C16.3828 11.9639 16.5092 12.3223 16.5001 12.6901Z"
                    stroke="#212121"
                    stroke-opacity="0.6"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_467_1521">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <div className="details">
                <p className="label">Contact Number</p>
                <p className="data">+{userInfo?.mobile}</p>
              </div>
            </div>

            <div className="user-field">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                className="field-icon"
              >
                <path
                  d="M6 1.5V4.5M12 1.5V4.5"
                  stroke="#212121"
                  stroke-opacity="0.6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z"
                  stroke="#212121"
                  stroke-opacity="0.6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.25 7.5H15.75"
                  stroke="#212121"
                  stroke-opacity="0.6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div className="details">
                <p className="label">Date of Birth</p>
                <p className="data">{moment(userInfo?.birthday).format("L")}</p>
              </div>
            </div>
          </div>

          {!isB2BUser ? (
            <Button
              className="mob-edit-btn"
              startIcon={<Icon icon="lucide:pencil" height={18} width={18} />}
              onClick={() => setEditView(true)}
              variant="outlined"
            >
              Edit Profile
            </Button>
          ) : null}
        </div>
      )}

      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        className="session-drawer"
      >
        <EcnButton
          startIcon={
            <Icon icon="material-symbols:arrow-back" height={24} width={24} />
          }
          color="secondary"
          className="back-btn"
          onClick={() => setOpen(false)}
          sx={{
            width: "max-content",
            marginBottom: "35px",
            fontSize: "18px",
            fontWeight: "600",
            color: "#212121",
          }}
        >
          Upload Profile Photo
        </EcnButton>

        <DropZone
          data={editData}
          setData={setEditData}
          setOpen={setOpen}
          success={success}
          setSuccess={setSuccess}
          edit={edit}
          setEdit={setEdit}
        />
      </Drawer>
    </div>
  );
};

export default layoutOne(Profile);
