import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const postLogoutSession = createAsyncThunk(
  "postLogoutSession",
  async (sessionId: string) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + AUTH_BASE}/v1/sessions/logout`,
      { session_id: sessionId }
    );
    return data;
  }
);

const postLogoutSessionSlice = createSlice({
  name: "postLogoutSession",
  initialState: {
    loading: false,
    logoutResponse: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postLogoutSession.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postLogoutSession.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.logoutResponse = action.payload;
    });
    builder.addCase(postLogoutSession.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default postLogoutSessionSlice.reducer;
