import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const AUTH_BASE = process.env.REACT_APP_AUTH_BASE || "";

export const getSessions = createAsyncThunk("getSessions", async () => {
  const { data } = await axios.get(`${GATEWAY_BASE + AUTH_BASE}/v1/sessions`);
  return data;
});

const getSessionsSlice = createSlice({
  name: "getSessions",
  initialState: {
    loading: false,
    sessionData: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSessions.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSessions.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.sessionData = action.payload;
    });
    builder.addCase(getSessions.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getSessionsSlice.reducer;
