import { useState } from "react";
import { Icon } from "@iconify/react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const NewSideBar = () => {
  const location = useLocation();

  const { isB2BUser } = useAppSelector((state) => state.userDetails);

  const [active, setActive] = useState(false);

  const activeHandler = () => {
    setActive(!active);
  };

  // Filter the url array based on the isB2BUser flag
  const filteredUrl = url.filter((item) => {
    if (isB2BUser && (item.title === "Billings" || item.title === "Subscriptions")) {
      return false;
    }
    return true;
  });

  return (
    <div className={active ? "sidebar collapse" : "sidebar"}>
      <div className="switch" onClick={activeHandler}>
        {active ? (
          <Icon icon="lucide:arrow-right-to-line" className="switch-icon" />
        ) : (
          <Icon icon="lucide:arrow-left-to-line" className="switch-icon" />
        )}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          viewBox="0 0 30 30"
          fill="none"
        >
          <circle cx="15" cy="15" r="15" fill="white" />
          <circle
            cx="15"
            cy="15"
            r="14.5"
            stroke="black"
            strokeOpacity="0.2"
            strokeDasharray="3 3"
          />
        </svg>
      </div>
      {!active ? (
        <div className="expended-view">
          <div className="item">
            {filteredUrl.map((item: any) => (
              <Link
                key={item.title} // Add a unique key for each item
                to={item.urlLink}
                className={
                  location.pathname === item.urlLink ? "top-head active" : "top-head"
                }
              >
                {item?.icon}
                {item?.title}
              </Link>
            ))}
          </div>
        </div>
      ) : (
        <div className="collapse-view">
          {filteredUrl.map((item: any) => (
            <p
              key={item.title} // Add a unique key for each item
              className={
                location.pathname === item?.urlLink ? "top-head active" : "top-head"
              }
            >
              <Link to={item.urlLink}>{item?.icon}</Link>
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default NewSideBar;

const url = [
  {
    title: "Profile",
    icon: <Icon icon="lucide:circle-user" className="icons" />,
    urlLink: "/",
  },
  {
    title: "Security",
    urlLink: "/security",
    icon: <Icon icon="lucide:shield-check" className="icons" />,
  },
  {
    title: "Billings",
    urlLink: "/billings",
    icon: <Icon icon="lucide:receipt" className="icons" />,
  },
  {
    title: "Subscriptions",
    icon: <Icon icon="lucide:crown" className="icons" />,
    urlLink: "/subscriptions",
  },
  {
    title: "Help & support",
    icon: <Icon icon="lucide:help-circle" className="icons" />,
    urlLink: "/support",
  },
];
