import { Icon } from "@iconify/react";
import {
  Dialog,
  DialogContent,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  deleteSecondaryEmail,
  resetDeleteSecondary,
} from "../../../redux/slices/securitySlices/deleteSecondaryEmailSlice";
import { enqueueSnackbar } from "notistack";
import { userDetails } from "../../../redux/slices/userSlices/userDetailsSlice";
import {
  postChangePrimaryEmail,
  resetChangePrimary,
} from "../../../redux/slices/securitySlices/postChangePrimaryEmailSlice";
import EcnButton from "../../StyledComponents/EcnButton";
import theme from "../../../theme";

const EmailRow = ({ email }: { email: string }) => {
  const dispatch = useAppDispatch();

  const { success } = useAppSelector((state) => state.deleteSecondaryEmail);
  const { success: primarySuccess } = useAppSelector(
    (state) => state.postChangePrimaryEmail
  );

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmPrimary, setConfirmPrimary] = useState(false);
  const [touched, setTouched] = useState(false);
  const [primaryTouch, setPrimaryTouch] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (success && touched) {
      setTouched(false);
      dispatch(resetDeleteSecondary());
      dispatch(userDetails());

      enqueueSnackbar("Email deleted successfully!", {
        variant: "success",
      });
    }
  }, [success, touched]);

  useEffect(() => {
    if (primarySuccess && primaryTouch) {
      setPrimaryTouch(false);
      dispatch(resetChangePrimary());
      dispatch(userDetails());
      enqueueSnackbar("Primary email updated successfully!", {
        variant: "success",
      });
    }
  }, [primarySuccess, primaryTouch]);

  const menuOpenHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const makePrimaryHandler = () => {
    dispatch(postChangePrimaryEmail(email));
    setPrimaryTouch(true);
    cancelSubHandler();
  };

  const deleteEmailHandler = () => {
    dispatch(deleteSecondaryEmail(email));
    setTouched(true);
    cancelSubHandler();
  };

  const cancelSubHandler = () => {
    setAnchorEl(null);
    setConfirmDelete(false);
    setConfirmPrimary(false);
  };
  return (
    <div className="email">
      <p className="id">{email}</p>
      <IconButton
        id="email-button"
        aria-controls={open ? "email-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={menuOpenHandler}
      >
        <Icon icon="lucide:ellipsis-vertical" />
      </IconButton>

      <Menu
        id="email-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={cancelSubHandler}
        MenuListProps={{
          "aria-labelledby": "email-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={() => setConfirmPrimary(true)}
          sx={{
            fontSize: "14px",
            color: "rgba(33, 33, 33, 0.7)",
            fontWeight: "500",
          }}
        >
          Set as Primary
        </MenuItem>
        <MenuItem
          onClick={() => setConfirmDelete(true)}
          sx={{
            fontSize: "14px",
            color: "rgba(33, 33, 33, 0.7)",
            fontWeight: "500",
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      <Dialog
        open={confirmDelete}
        onClose={() => setConfirmDelete(false)}
        PaperProps={{ sx: { borderRadius: "8px" } }}
      >
        <DialogContent
          sx={{
            md: { margin: "17px 40px" },
            sm: { margin: "16px" },
            xs: { margin: "12px" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "25px",
              fontWeight: "600",
              color: "#212121",
              textAlign: "center",
              [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
              },
            }}
          >
            Are you sure you want to delete this email address?
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            sx={{
              marginTop: "32px",
              gap: "55px",
              [theme.breakpoints.down("sm")]: {
                gap: "10px",
              },
            }}
          >
            <EcnButton
              variant="outlined"
              sx={{
                borderRadius: "9px",
                border: "1px solid rgba(33, 33, 33, 0.2)",
                minWidth: "200px",
                padding: "9px 19px",
                fontSize: "18px",
                fontWeight: 600,
                [theme.breakpoints.down("sm")]: {
                  minWidth: "100px",
                  fontSize: "14px",
                },
              }}
              onClick={deleteEmailHandler}
            >
              Confirm
            </EcnButton>
            <EcnButton
              variant="outlined"
              color="error"
              sx={{
                borderRadius: "9px",
                minWidth: "200px",
                padding: "9px 19px",
                fontSize: "18px",
                fontWeight: 600,
                [theme.breakpoints.down("sm")]: {
                  minWidth: "100px",
                  fontSize: "14px",
                },
              }}
              onClick={() => setConfirmDelete(false)}
            >
              Cancel
            </EcnButton>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        open={confirmPrimary}
        onClose={() => setConfirmPrimary(false)}
        PaperProps={{ sx: { borderRadius: "8px" } }}
      >
        <DialogContent
          sx={{
            md: { margin: "17px 40px" },
            sm: { margin: "16px" },
            xs: { margin: "12px" },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: "25px",
              fontWeight: "600",
              color: "#212121",
              textAlign: "center",
              [theme.breakpoints.down("sm")]: {
                fontSize: "16px",
              },
            }}
          >
            Are you sure you want to set this email address as primary?
          </Typography>
          <Stack
            direction="row"
            gap="55px"
            justifyContent="center"
            sx={{
              marginTop: "32px",
              gap: "55px",
              [theme.breakpoints.down("sm")]: {
                gap: "10px",
              },
            }}
          >
            <EcnButton
              variant="outlined"
              sx={{
                borderRadius: "9px",
                border: "1px solid rgba(33, 33, 33, 0.2)",
                minWidth: "200px",
                padding: "9px 19px",
                fontSize: "18px",
                fontWeight: 600,
                [theme.breakpoints.down("sm")]: {
                  minWidth: "100px",
                  fontSize: "14px",
                },
              }}
              onClick={makePrimaryHandler}
            >
              Confirm
            </EcnButton>
            <EcnButton
              variant="outlined"
              color="error"
              sx={{
                borderRadius: "9px",
                border: "1px solid rgba(33, 33, 33, 0.2)",
                minWidth: "200px",
                padding: "9px 19px",
                fontSize: "18px",
                fontWeight: 600,
                [theme.breakpoints.down("sm")]: {
                  minWidth: "100px",
                  fontSize: "14px",
                },
              }}
              onClick={() => setConfirmPrimary(false)}
            >
              Cancel
            </EcnButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EmailRow;
