import { useCallback, useEffect, useMemo, useState, FC, useRef } from "react";
import { useDropzone } from "react-dropzone";
import { Icon } from "@iconify/react";

import EcnButton from "../StyledComponents/EcnButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Button, CircularProgress } from "@mui/material";
import getCroppedImg from "./cropImage";
import Cropper from "react-easy-crop";
import { postUserProfileImage } from "../../redux/slices/userSlices/postUserProfileImageSlice";

const baseStyles = {
  display: "flex",
  width: "95%",
  alignItems: "center",
  padding: "20px",

  cursor: "pointer",
  color: "#ffff",
  transition: "border .3s ease-in-out",
  margin: "auto",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const DropZone: React.FC<{
  data: any;
  setData: any;
  setOpen: any;
  success: any;
  setSuccess: any;
  edit: any;
  setEdit: any;
}> = ({ data, setData, setOpen, success, setSuccess, edit, setEdit }) => {
  const dispatch = useAppDispatch();
  const { imgUploadData, loading, error } = useAppSelector(
    (state: any) => state.postUserProfileImage
  );

  const onDrop = useCallback((acceptedFiles: any) => {
    setFiles(
      acceptedFiles.map((file: any) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
    setSuccess(true);
    setImageUrl(acceptedFiles[0].preview);
  }, []);

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      maxFiles: 1,
      accept: {
        "image/png": [".png"],
        "image/jpeg": [".jpeg"],
        "image/svg": [".svg"],
      },
    });
  const style = useMemo(
    () => ({
      ...baseStyles,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedImage, setCroppedImage] = useState<any>();
  const [firstCroppedAreaPixel, setFirstCroppedAreaPixel] = useState<any>(null);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageUrl, setImageUrl] = useState<any>("");
  const [mediaSize, setMediaSize] = useState<any>();
  const [uploadClick, setUploadClick] = useState<boolean>(false);
  const [files, setFiles] = useState<any>([]);
  const [progress, setProgress] = useState<number>(0);
  const [uploadError, setUploadError] = useState<boolean>(false);

  useEffect(() => {
    if (firstCroppedAreaPixel === null) {
      setFirstCroppedAreaPixel(croppedAreaPixels);
    }
    if (firstCroppedAreaPixel && croppedAreaPixels !== firstCroppedAreaPixel) {
      setEdit(true);
    }
  }, [croppedAreaPixels]);

  useEffect(() => {
    if (
      !loading &&
      imgUploadData &&
      uploadClick &&
      Array.isArray(imgUploadData?.urls)
    ) {
      setData({
        ...data,
        display_pictures: imgUploadData?.urls,
      });
      setProgress(100);
      setTimeout(() => {
        setOpen(false);
        setSuccess(false);
        setUploadClick(false);
      }, 500);
    }
  }, [imgUploadData, loading]);

  useEffect(() => {
    if (!loading && error && uploadClick) {
      setUploadError(true);
    }
  }, [error, loading]);

  // clean up
  useEffect(
    () => () => {
      files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  useEffect(() => {
    if (uploadClick) {
      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 90 ? prevProgress : prevProgress + 5
        );
      }, 500);

      return () => {
        clearInterval(timer);
      };
    }
  }, [uploadClick]);

  const onCropComplete = useCallback(
    async (croppedArea: any, croppedAreaPixels: any) => {
      setCroppedAreaPixels(croppedAreaPixels);
    },
    []
  );

  const mediaSizeHandler = useCallback(async (mediaSize: any) => {
    setMediaSize(mediaSize);
    const num = Math.floor(Math.max(300 - mediaSize.width, 300 - mediaSize.height));
    setZoom(1 + Math.ceil(num / 20) * 0.1);
  }, []);

  const undoHandler = () => {
    const num = Math.floor(Math.max(300 - mediaSize.width, 300 - mediaSize.height));
    setZoom(1 + Math.ceil(num / 20) * 0.1);
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage: any = await getCroppedImg(imageUrl, croppedAreaPixels);

      setCroppedImage(croppedImage);
      setUploadClick(true);
      const img = convertToFile(croppedImage);
      const formData = new FormData();
      formData.append("file", img);
      dispatch(postUserProfileImage(formData));
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels]);

  const convertToFile = (base64: string) => {
    let arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const extension = mime.split("/")[1];
    const fileName = new Date().getTime() + `.${extension}`;
    return new File([u8arr], fileName, { type: mime });
  };

  const zoomInHandler = async () => {
    if (zoom < 2.5) setZoom((prev) => prev + 0.1);
  };

  const zoomOutHandler = () => {
    const newVal = zoom - 0.1;
    if (newVal >= 1) setZoom((prev) => prev - 0.1);
  };

  return (
    <>
      {uploadError ? (
        <div className="add-file">
          <div className="wrapper">
            <div className="error">
              <img src={croppedImage} alt="" />
              <h3>Upload failed!</h3>
              <Button
                variant="outlined"
                className="btn-oulined"
                onClick={showCroppedImage}
              >
                Retry
              </Button>
            </div>
          </div>
        </div>
      ) : success ? (
        <div className="add-file">
          <div className="wrapper">
            {!uploadClick ? (
              <div className="filename">
                <div className="container">
                  <div className="cropper">
                    {" "}
                    <Cropper
                      image={imageUrl}
                      crop={crop}
                      zoom={zoom}
                      aspect={1 / 1}
                      cropShape="round"
                      onCropChange={setCrop}
                      onCropComplete={onCropComplete}
                      onZoomChange={setZoom}
                      setMediaSize={mediaSizeHandler}
                      cropSize={{ width: 300, height: 300 }}
                      style={{
                        mediaStyle: { width: "300px" },
                        containerStyle: {
                          height: "300px",
                          width: "300px",
                          margin: "auto",
                        },
                      }}
                    />
                  </div>

                  <div className="zoom-buttons">
                    <Button className="btn-text" onClick={zoomInHandler}>
                      <Icon icon="fad:zoomin" className="icon" />
                      Zoom in
                    </Button>
                    <Button className="btn-text" onClick={zoomOutHandler}>
                      <Icon icon="fad:zoomout" className="icon" />
                      Zoom out
                    </Button>
                  </div>
                </div>

                <div className="buttons">
                  {edit ? (
                    <>
                      <Button
                        variant="outlined"
                        className="btn-oulined"
                        startIcon={
                          <Icon icon="lucide:undo-2" className="undo-icon" />
                        }
                        onClick={undoHandler}
                      >
                        Undo
                      </Button>
                      <Button
                        variant="contained"
                        onClick={showCroppedImage}
                        className="save-changes"
                      >
                        Save changes
                      </Button>
                    </>
                  ) : (
                    <Button
                      variant="contained"
                      className="btn-contained"
                      onClick={showCroppedImage}
                    >
                      Upload image
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              <div className="progress">
                <div className="image-loader">
                  <img src={croppedImage} alt="" />
                  <CircularProgress
                    variant="determinate"
                    thickness={1.1}
                    size="325px"
                    value={progress}
                    className="circular-progress"
                  />
                </div>

                <h3>Please wait</h3>
                <div className="in-progress">
                  <p>Uploading in progress</p>
                  <Icon icon="line-md:upload-loop" className="icon" />
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="wrapper-upload">
          <div {...getRootProps({ style })}>
            <input {...getInputProps()} />

            <div className="centeral-part">
              <div className="button">
                <EcnButton>
                  <Icon icon="akar-icons:image" className="icon" />
                </EcnButton>
              </div>

              <div className="text">
                <p className="sub-text"> Drag photo here </p>

                <p className="sub-text-2">or</p>
              </div>
              <Button className="btn-outlined">
                <Icon icon="lucide:monitor" className="icon" />
                Upload from device
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DropZone;
