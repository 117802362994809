import SideBar from "../Nav/Sidebar";
import Header from "../Nav/Header";

export default function layoutOne(Component: any) {
  function LayoutOne(props: any) {
    return (
      <div className="layout-one">
        <Header />
        <div className="page">
          <div className="left">
            <SideBar />
          </div>
          <div className="right">
            <Component {...props} />
          </div>
        </div>
      </div>
    );
  }

  return LayoutOne;
}
