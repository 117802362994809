import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const CORE_BASE = process.env.REACT_APP_CORE_BASE || "";

interface SupportProps {
  name: string;
  mobile: string;
  email: string;
  alternate_email: string;
  subject: string;
  description: string;
}

export const postSupportRequest = createAsyncThunk(
  "postSupportRequest",
  async (supportData: SupportProps) => {
    const { data } = await axios.post(
      `${GATEWAY_BASE + CORE_BASE}/api/v1/support`,
      supportData
    );
    return data;
  }
);

const postSupportRequestSlice = createSlice({
  name: "postSupportRequest",
  initialState: {
    loading: false,
    supportResponse: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postSupportRequest.pending, (state, action) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(postSupportRequest.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.supportResponse = action.payload;
    });
    builder.addCase(postSupportRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default postSupportRequestSlice.reducer;
