import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const CORE_BASE = process.env.REACT_APP_CORE_BASE || "";

export const postUserProfileImage = createAsyncThunk(
  "postUserProfileImage",
  async (user: any) => {

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    const { data } = await axios.post(
      `${GATEWAY_BASE + CORE_BASE}/api/v1/storage/profile/upload`,
      user,
      config
    );
    return data;
  }
);

const postUserProfileImageSlice = createSlice({
  name: "postUserProfileImage",
  initialState: {
    loading: false,
    imgUploadData: null,
    error: false,
    success: false,
  },
  reducers: {
    resetPatchUser: (state) => {
      state.loading = false;
      state.imgUploadData = null;
      state.error = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postUserProfileImage.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(postUserProfileImage.fulfilled, (state, action) => {
      state.loading = false;
      state.imgUploadData = action.payload;
      state.success = true;
    });
    builder.addCase(postUserProfileImage.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.success = false;
    });
  },
});

export const { resetPatchUser } = postUserProfileImageSlice.actions;

export default postUserProfileImageSlice.reducer;
