import { useEffect, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { getBillingHistory } from "../redux/slices/subscriptionSlices/getBillingHistorySlice";
import { getBillInvoice } from "../redux/slices/subscriptionSlices/getBillInvoiceSlice";

import moment from "moment";
import { Button } from "@mui/material";

import layoutOne from "../components/layouts/layoutOne";

const Billings = () => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const { billingHistory } = useAppSelector((state) => state.getBillingHistory);
  const { billInvoice } = useAppSelector((state) => state.getBillInvoice);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getBillingHistory());
  }, []);

  useEffect(() => {
    if (billInvoice && open) {
      const link = document.createElement("a");
      link.href = billInvoice.message;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setOpen(false);
    }
  }, [billInvoice, open]);

  const invoiceClickHandler = (invoiceId: string) => {
    dispatch(getBillInvoice(invoiceId));
    setOpen(true);
  };

  return (
    <div className="billings">
      <h5>Billing History</h5>

      <div className="bill-list">
        {billingHistory?.transactions?.map((bill: any, index: number) => (
          <div className="bill" key={index}>
            <div className="top">
              <p className="name">{bill.subscriptionName}</p>
            </div>
            <div className="bottom">
              <div className="all-fields">
                <div className="sub-field">
                  <p className="heading">Amount</p>
                  <p className="data">${bill?.price?.amount}</p>
                </div>

                <div className="sub-field">
                  <p className="heading">Next Billing Date</p>
                  <p className="data">
                    {moment.unix(bill.servicePeriod.End).format("ll")}
                  </p>
                </div>

                <div className="sub-field">
                  <p className="heading">Order Id</p>
                  <p className="data">{bill.invoiceId}</p>
                </div>
              </div>

              <Button
                variant="outlined"
                className="invoice-btn"
                onClick={() => invoiceClickHandler(bill.invoiceId)}
              >
                Download Invoice
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default layoutOne(Billings);
