import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../redux/hooks";

import { userSignin } from "../redux/slices/userSlices/userSigninSlice";
import { b2bUserDetails } from "../redux/slices/userSlices/b2bUserDetailsSlice";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import EcnInput from "../components/StyledComponents/EcnInput";

const IMG_BASE = process.env.REACT_APP_IMG_BASE;

const SignIn = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    token,
    error: loginError,
    loading,
  } = useAppSelector((state: any) => state.userSignIn);

  const { userInfo, loading: userLoading } = useAppSelector(
    (state: any) => state.userDetails
  );

  const [userData, setUserData] = useState({
    email: "",
    password: "",
    enterprise: true,
    admin: true,
    roles: ["enterprise-admin"],
  });
  const [error, setError] = React.useState({
    status: false,
    message: "",
  });
  const [click, setClick] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (token && click) {
      dispatch(b2bUserDetails());
    }
  }, [token]);

  useEffect(() => {
    if (userInfo && location.search) {
      navigate(`${location.search.split("=")[1]}`);
    } else if (userInfo) {
      //navigate("/dashboard");
    }
  }, [userInfo, location.search, userLoading]);

  useEffect(() => {
    if (loginError) {
      setError({
        status: true,
        message:
          "Unable to sign in. Please ensure you're using the correct credentials.",
      });
    }
  }, [loginError]);

  const emailHandler = (e: any) => {
    setUserData({ ...userData, email: e.target.value });
  };

  const passwordHandler = (e: any) => {
    setUserData({ ...userData, password: e.target.value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (userData.email === "" || userData.password === "") {
      setError({
        status: true,
        message: "Please enter your email and password",
      });
    } else {
      setClick(true);
      dispatch(userSignin(userData));
    }
  };
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  return (
    <div className="signIn">
      <div className="container">
        <div className="wrapper">
          <img src="/elevate_enterprise.png" alt="Elevate" className="main_logo" />
          <div className="login-detail">
            <h1>Login to your account</h1>

            <div className="input-text">
              <p>Email</p>
              <EcnInput
                className="ecn-input"
                placeholder="Enter your Email"
                onChange={emailHandler}
              />
            </div>
            <div className="input-text">
              <p>Password</p>

              <FormControl fullWidth>
                <OutlinedInput
                  size="small"
                  className="outline-input"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  value={userData.password}
                  onChange={passwordHandler}
                />
              </FormControl>

              <Link to="/forgot-password">Forgot password?</Link>
            </div>

            {error?.status && (
              <Alert severity="error" sx={{ marginBottom: "30px", width: "100%" }}>
                {error?.message}
              </Alert>
            )}
            <Button onClick={onSubmit} variant="contained" className="btn-contained">
              Log in
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
