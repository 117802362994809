import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const PAYMENTS_BASE = process.env.REACT_APP_PAYMENTS_BASE || "";

export const getBillInvoice = createAsyncThunk(
  "getBillInvoice",
  async (invoiceId: string) => {
    const { data } = await axios.get(
      `${GATEWAY_BASE + PAYMENTS_BASE}/api/v1/get_invoice/${invoiceId}`
    );
    return data;
  }
);

const getBillInvoiceSlice = createSlice({
  name: "getBillInvoice",
  initialState: {
    loading: false,
    billInvoice: null as any,
    error: false,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBillInvoice.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBillInvoice.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.billInvoice = action.payload;
    });
    builder.addCase(getBillInvoice.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getBillInvoiceSlice.reducer;
