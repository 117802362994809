import { useState } from "react";
import { Icon } from "@iconify/react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import moment from "moment";

const SubCard = ({ sub }: { sub: any }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const menuOpenHandler = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const cancelSubHandler = () => {
    setAnchorEl(null);
  };
  return (
    <div className="sub">
      <div className="top">
        <p className="name">{sub.tierName}</p>
        <IconButton
          size="small"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={menuOpenHandler}
          sx={{display: "none"}}
        >
          <Icon icon="lucide:ellipsis-vertical" />
        </IconButton>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={cancelSubHandler}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={cancelSubHandler}
            sx={{
              fontSize: "14px",
              color: "rgba(33, 33, 33, 0.7)",
              fontWeight: "500",
            }}
          >
            Cancel Subscription
          </MenuItem>
        </Menu>
      </div>
      <div className="bottom">
        <div className="sub-field">
          <p className="heading">Amount</p>
          <p className="data">${sub.tierData?.price?.amount}</p>
        </div>

        <div className="sub-field">
          <p className="heading">Next Billing Date</p>
          <p className="data">{moment.unix(sub.validTillDate).format("ll")}</p>
        </div>

        <div className="sub-field">
          <p className="heading">Order Id</p>
          <p className="data">{sub.id}</p>
        </div>
      </div>
    </div>
  );
};

export default SubCard;
