import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../axiosInterceptor";

const GATEWAY_BASE = process.env.REACT_APP_API_GATEWAY_BASE;
const ADMIN_BASE = process.env.REACT_APP_ADMIN_SERVICES_BASE || "";

export const getAdminDetails = createAsyncThunk("getAdminDetails", async () => {
  try {
    const { data } = await axios.get(`${GATEWAY_BASE + ADMIN_BASE}/adminDetails`);

    return data;
  } catch (error: any) {
    // Throw the entire error object to be caught in the rejected case
    throw error.response?.data.statusCode;
  }
});

const getAdminDetailsSlice = createSlice({
  name: "getAdminDetails",
  initialState: {
    loading: false,
    adminInfo: null as any,
    error: false,
    errorData: null as any,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAdminDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAdminDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.adminInfo = action.payload;
    });
    builder.addCase(getAdminDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
      state.errorData = action.error;
    });
  },
});

export default getAdminDetailsSlice.reducer;
